<template>
  <vx-card :title="this.pageTitle">
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-table max-items="10" pagination search stripe :data="options" :hoverFlat="true">
          <template slot="thead">
            <vs-th sort-key="institution">Institución</vs-th>
            <vs-th sort-key="type">Tipo</vs-th>
            <vs-th sort-key="moduleName">Módulo</vs-th>
            <vs-th>Acciones</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="record" :key="index" v-for="(record, index) in data">
              <vs-td><p class="font-medium">{{ record.institution }}</p></vs-td>
              <vs-td><p class="font-medium">{{ record.type }}</p></vs-td>
              <vs-td><p class="font-medium">{{ record.moduleName }}</p></vs-td>
              <vs-td>
                <div class="flex">
                  <vs-button
                    size="small" color="success" type="border" icon-pack="feather" :icon="record.buttonSettings.icon"
                    @click="goToOption(record)"
                  >
                    {{ record.buttonSettings.text }}
                  </vs-button>
                </div>
              </vs-td>
            </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </vx-card>
</template>

<script>
  import AttributeOptionsClient from "../../utils/attributeOptionsClient";

  const ATTRIBUTE_OPTIONS_CLIENT = new AttributeOptionsClient("products");

  export default {
    data() {
      return {
        pageTitle: "Banners y Slides",
        options: [
          {
            type: "Carrusel",
            moduleName: "Index",
            institution: "Banco",
            buttonSettings: {text: "Configurar", icon: "icon-film"},
            routeSettings: {
              name: "media-object-slides",
              params: {
                institution: "bank",
                type: "carousel-index",
              },
            },
          },
          {
            type: "Banner",
            moduleName: "Personas",
            institution: "Banco",
            buttonSettings: {text: "Configurar", icon: "icon-image"},
            routeSettings: {
              name: "media-object-slides",
              params: {institution: "bank", type: "people-banner"},
            },
          },
          {
            type: "Banner",
            moduleName: "Promociones",
            institution: "Banco",
            buttonSettings: {text: "Configurar", icon: "icon-image"},
            routeSettings: {
              name: "media-object-slides",
              params: {
                institution: "bank",
                type: "promotions-banner"
              }
            }
          },
          {
            type: "Banner",
            moduleName: "Plazas Disponibles",
            institution: "Banco",
            buttonSettings: {text: "Configurar", icon: "icon-image"},
            routeSettings: {
              name: "media-object-slides",
              params: {
                institution: "bank",
                type: "jobs-banner"
              }
            }
          },
          {
            type: "Banner",
            moduleName: "Documentos",
            institution: "Banco",
            buttonSettings: {text: "Configurar", icon: "icon-image"},
            routeSettings: {
              name: "media-object-slides",
              params: {
                institution: "bank",
                type: "documents-banner"
              }
            }
          },
          {
            type: "Banner",
            moduleName: "Descuentos",
            institution: "Banco",
            buttonSettings: {text: "Configurar", icon: "icon-image"},
            routeSettings: {
              name: "media-object-slides",
              params: {
                institution: "bank",
                type: "discounts-banner"
              }
            }
          },
          {
            type: "Banner",
            moduleName: "Proyectos Inmobiliarios",
            institution: "Banco",
            buttonSettings: {text: "Configurar", icon: "icon-image"},
            routeSettings: {
              name: "media-object-slides",
              params: {
                institution: "bank",
                type: "projects-banner"
              }
            }
          },
          {
            type: "Banner",
            moduleName: "Zona de Pagos",
            institution: "Banco",
            buttonSettings: {text: "Configurar", icon: "icon-image"},
            routeSettings: {
              name: "media-object-slides",
              params: {
                institution: "bank",
                type: "payments-banner"
              }
            }
          },
          {
            type: "Banner",
            moduleName: "Activos Eventuales",
            institution: "Banco",
            buttonSettings: {text: "Configurar", icon: "icon-image"},
            routeSettings: {
              name: "media-object-slides",
              params: {
                institution: "bank",
                type: "assets-banner"
              }
            }
          },
          {
            type: "Carrusel",
            moduleName: "Index",
            institution: "Seguros",
            buttonSettings: {text: "Configurar", icon: "icon-film"},
            routeSettings: {
              name: "media-object-slides",
              params: {
                institution: "insurances",
                type: "carousel-index"
              }
            }
          },
          {
            type: "Banner",
            moduleName: "Promociones",
            institution: "Seguros",
            buttonSettings: {text: "Configurar", icon: "icon-image"},
            routeSettings: {
              name: "media-object-slides",
              params: {
                institution: "insurances",
                type: "promotions-banner"
              }
            }
          },
          {
            type: "Banner",
            moduleName: "Plazas Disponibles",
            institution: "Seguros",
            buttonSettings: {text: "Configurar", icon: "icon-image"},
            routeSettings: {
              name: "media-object-slides",
              params: {
                institution: "insurances",
                type: "jobs-banner"
              }
            }
          },
          {
            type: "Banner",
            moduleName: "Documentos",
            institution: "Seguros",
            buttonSettings: {text: "Configurar", icon: "icon-image"},
            routeSettings: {
              name: "media-object-slides",
              params: {
                institution: "insurances",
                type: "documents-banner"
              }
            }
          },
          {
            type: "Banner",
            moduleName: "Red de Proveedores",
            institution: "Seguros",
            buttonSettings: {text: "Configurar", icon: "icon-image"},
            routeSettings: {
              name: "media-object-slides",
              params: {
                institution: "insurances",
                type: "providers-banner"
              }
            }
          },
          {
            type: "Banner",
            moduleName: "Salvamentos",
            institution: "Seguros",
            buttonSettings: {text: "Configurar", icon: "icon-image"},
            routeSettings: {
              name: "media-object-slides",
              params: {
                institution: "insurances",
                type: "salvages-banner"
              }
            }
          },
          {
            type: "Banner",
            moduleName: "Glosario",
            institution: "Seguros",
            buttonSettings: {text: "Configurar", icon: "icon-image"},
            routeSettings: {
              name: "media-object-slides",
              params: {
                institution: "insurances",
                type: "terminologies-banner"
              }
            }
          },
          {
            type: "Carrusel",
            moduleName: "Index",
            institution: "Empresas",
            buttonSettings: {text: "Configurar", icon: "icon-film"},
            routeSettings: {
              name: "media-object-slides",
              params: {
                institution: "companies",
                type: "carousel-index"
              }
            }
          },
          {
            type: "Banner",
            moduleName: "Documentos",
            institution: "Empresas",
            buttonSettings: {text: "Configurar", icon: "icon-image"},
            routeSettings: {
              name: "media-object-slides",
              params: {
                institution: "companies",
                type: "documents-banner"
              }
            }
          },
          {
            type: "Banner",
            moduleName: "Plazas Disponibles",
            institution: "Empresas",
            buttonSettings: {text: "Configurar", icon: "icon-image"},
            routeSettings: {
              name: "media-object-slides",
              params: {
                institution: "companies",
                type: "jobs-banner"
              }
            }
          },
          {
            type: "Banner",
            moduleName: "Promociones",
            institution: "Empresas",
            buttonSettings: {text: "Configurar", icon: "icon-image"},
            routeSettings: {
              name: "media-object-slides",
              params: {
                institution: "companies",
                type: "promotions-banner"
              }
            }
          },
        ],
      };
    },

    mounted() {
      let insurancesCategoriesStartIndex = 10
      this.getCategories("insurances")
        .then(categories => {
          categories.forEach(category => {
            this.options.splice(insurancesCategoriesStartIndex, 0, {
              type: "banner",
              moduleName: `Productos -> ${category.name}`,
              institution: "Seguros",
              buttonSettings: {text: "Configurar", icon: "icon-image"},
              routeSettings: {
                name: "media-object-slides",
                params: {
                  institution: "insurances",
                  type: `productos-${category.slug}-banner`
                }
              }
            });
            insurancesCategoriesStartIndex++;
          });
        });

      let bankCategoriesStartIndex = 1;
      this.getCategories("bank")
        .then(categories => {
          categories.forEach(category => {
            this.options.splice(bankCategoriesStartIndex, 0, {
              type: "banner",
              moduleName: `Productos -> ${category.name}`,
              institution: "Banco",
              buttonSettings: {text: "Configurar", icon: "icon-image"},
              routeSettings: {
                name: "media-object-slides",
                params: {
                  institution: "bank",
                  type: `productos-${category.slug}-banner`
                }
              }
            });
            bankCategoriesStartIndex++;
          });
        });

      let companiesCategoriesStartIndex = 19;
      this.getCategories("companies")
        .then(categories => {
          categories.forEach(category => {
            this.options.splice(companiesCategoriesStartIndex, 0, {
              type: "banner",
              moduleName: `Productos -> ${category.name}`,
              institution: "Empresas",
              buttonSettings: {text: "Configurar", icon: "icon-image"},
              routeSettings: {
                name: "media-object-slides",
                params: {
                  institution: "companies",
                  type: `productos-${category.slug}-banner`
                }
              }
            });
            companiesCategoriesStartIndex++;
          });
        });
    },

    methods: {
      goToOption(option) {
        this.$router.push({name: option.routeSettings.name, params: option.routeSettings.params});
      },

      async getCategories(institutionId) {
        const response = await ATTRIBUTE_OPTIONS_CLIENT.categories({
          pathParameters: {institutionId: institutionId}
        });
        return response.data;
      }
    },
  };
</script>

<style scoped>
  .vs-table--tbody-table .tr-values.selected {
    cursor: default;
  }
</style>
